import { useScript, defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin({
  name: "scripts:init",
  env: { islands: false },
  parallel: true,
  setup() {
    const adsbygoogle = useScript({"key":"adsbygoogle","src":"https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6575747924380084","type":"text/javascript","async":true}, { use: () => ({ adsbygoogle: window.adsbygoogle }) })
    const twitterTracking = useScript({"key":"twitterTracking","id":"twitter-tracking","innerHTML":"!function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);\n},s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',\na=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');\ntwq('config','ompqi');","type":"text/javascript","async":true}, { use: () => ({ twitterTracking: window.twitterTracking }) })
    const metaTracking = useScript({"key":"metaTracking","id":"meta-tracking","innerHTML":"!function(f,b,e,v,n,t,s)\n{if(f.fbq)return;n=f.fbq=function(){n.callMethod?\nn.callMethod.apply(n,arguments):n.queue.push(arguments)};\nif(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';\nn.queue=[];t=b.createElement(e);t.async=!0;\nt.src=v;s=b.getElementsByTagName(e)[0];\ns.parentNode.insertBefore(t,s)}(window,document,'script',\n'https://connect.facebook.net/en_US/fbevents.js');\n fbq('init', '482340830835122'); \nfbq('track', 'PageView');","type":"text/javascript","async":true}, { use: () => ({ metaTracking: window.metaTracking }) })
    const twitterSignupEvent = useScript({"key":"twitterSignupEvent","id":"twitter-signup-event","type":"text/javascript","innerHTML":"twq('event', 'tw-ompqi-omt45', {\n      value: null, // use this to pass the value of the conversion (e.g. 5.00)\n      currency: null, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)\n      contents: [ // use this to pass an array of products or content\n        // add all items to the array\n        // use this for the first item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        },\n        // use this for the second item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        }],\n      status: null, // use this to pass the status of a sign up or subscription, (e.g. 'started' or 'completed')\n      conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')\n      email_address: null, // use this to pass a user’s email address\n      phone_number: null // phone number in E164 standard\n    });"}, { use: () => ({ twitterSignupEvent: window.twitterSignupEvent }) })
    const twitterSearchEvent = useScript({"key":"twitterSearchEvent","id":"twitter-search-event","type":"text/javascript","innerHTML":"twq('event', 'tw-ompqi-omssd', {\n      search_string: null, // use this to pass a term searched on your site (e.g. 'bird seed')\n      value: null, // use this to pass the value of the conversion (e.g. 5.00)\n      currency: null, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)\n      contents: [ // use this to pass an array of products or content\n        // add all items to the array\n        // use this for the first item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        },\n        // use this for the second item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        }],\n      conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')\n      email_address: null, // use this to pass a user’s email address\n      phone_number: null // phone number in E164 standard\n    });"}, { use: () => ({ twitterSearchEvent: window.twitterSearchEvent }) })
    const twitterDownloadEvent = useScript({"key":"twitterDownloadEvent","id":"twitter-download-event","type":"text/javascript","innerHTML":"twq('event', 'tw-ompqi-omt48', {\n    contents: [ // use this to pass an array of products or content\n        // add all items to the array\n        // use this for the first item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        },\n        // use this for the second item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        }], \n    conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')\n    email_address: null, // use this to pass a user’s email address\n    phone_number: null // phone number in E164 standard\n  });\n"}, { use: () => ({ twitterDownloadEvent: window.twitterDownloadEvent }) })
    const twitterViewEvent = useScript({"key":"twitterViewEvent","id":"twitter-content-view-event","type":"text/javascript","innerHTML":"twq('event', 'tw-ompqi-omt4b', {\n    value: null, // use this to pass the value of the conversion (e.g. 5.00)\n    currency: null, // use this to pass the currency of the conversion with an ISO 4217 code (e.g. ‘USD’)\n    contents: [ // use this to pass an array of products or content\n        // add all items to the array\n        // use this for the first item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        },\n        // use this for the second item\n        {\n          content_type: null,\n          content_id: null,\n          content_name: null,\n          content_price: null,\n          num_items: null,\n          content_group_id: null\n        }], \n    conversion_id: null, // use this to pass a unique ID for the conversion event for deduplication (e.g. order id '1a2b3c')\n    email_address: null, // use this to pass a user’s email address\n    phone_number: null // phone number in E164 standard\n  });\n"}, { use: () => ({ twitterViewEvent: window.twitterViewEvent }) })
    const cloudinary = useScript({"key":"cloudinary","src":"https://widget.cloudinary.com/v2.0/global/all.js","type":"text/javascript","async":true,"referrerpolicy":false,"crossorigin":false}, { use: () => ({ cloudinary: window.cloudinary }) })
    return { provide: { $scripts: { adsbygoogle, twitterTracking, metaTracking, twitterSignupEvent, twitterSearchEvent, twitterDownloadEvent, twitterViewEvent, cloudinary } } }
  }
})