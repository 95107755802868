import { useAuthStore } from "~/store/authStore";
import { RESPONSE_STATUS } from "~/constants";

export default defineNuxtRouteMiddleware((to) => {
  const store = useAuthStore();

  const ACCESSIBLE_PATHS = ["/", "/privacy", "/terms-of-service", "/continue-journey", "/password/request-reset", "/password/reset-password"];

  if (!ACCESSIBLE_PATHS.includes(to.path) && !store.isAuthenticated && useRuntimeConfig().public.siteMeta.betaMode === 1) {
    return abortNavigation(
      createError({
        ...RESPONSE_STATUS.Forbidden,
        statusMessage: "Access is restricted to invitation only. Please join the waitlist",
        fatal: true,
      }),
    );
  }
});
