/* eslint-disable @typescript-eslint/naming-convention */
import { getUnixTime } from "date-fns";
import { defu } from "defu";
import type { UseFetchOptions } from "nuxt/app";
import { COOKIE_KEYS } from "~/constants";

export async function useCustomFetch<T>(url: string, options: UseFetchOptions<T> = {}) {
  // for nice deep defaults, please use unjs/defu
  return useFetch(url, defu(options, setupDefaults<T>(url)));
}

function setupDefaults<T>(url: string): UseFetchOptions<T> {
  const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

  const app = useNuxtApp();

  const ttl = Number(useRuntimeConfig().public.fetchTtl);

  return {
    baseURL: useRuntimeConfig().public.apiBaseUrl as string,
    // cache request
    key: url,

    watch: false,

    deep: false,

    headers: getApiRequestHeaders(token.value),

    /* eslint-disable @typescript-eslint/no-unused-vars */
    onResponse(ctx) {
      // _ctx.response._data = new myBusinessResponse(_ctx.response._data)
    },
    /* eslint-disable @typescript-eslint/no-unused-vars */
    onResponseError(ctx) {
      // throw new myBusinessError()
    },
    transform(input) {
      return input
        ? {
            ...input,
            fetchedAt: getUnixTime(new Date()),
          }
        : input;
    },
    getCachedData(key) {
      const now = getUnixTime(new Date());
      const data = app.payload.data[key] || app.static.data[key];

      if (!data) {
        return data;
      }

      if (now - data.fetchedAt > ttl) {
        return;
      }

      return data;
    },
  };
}
