export const MIN_DESKTOP_WIDTH = 1024;

export const CURRENCY_NAME = "Engagement Points";

export const CURRENCY_ABBREVIATION = "EP";

export const DEFAULT_DESC_CHAR_LIMIT = 2000;

export const DEFAULT_CONTENT_CHAR_LIMIT = 30_000;

export const REPORTABLE_RESOURCE = {
  User: "user",
  Course: "course",
  Content: "content",
} as const;

export const REPORTABLE_REASONS = {
  Copyright: "Copyright violation",
  Spam: "Spam, misleading, or non-educational content",
  Sexual: "Sexual content",
  Violence: "Violent, harmful, or dangerous content",
  Hateful: "Hateful or abusive content",
  Bully: "Harassment, bullying or abuse",
  Misinformation: "Misinformation",
  Terrorism: "Promotes or incites terrorism",
  Legal: "Legal Issues",
  Other: "Other Issues",
} as const;

export const isReportContextRequired = (reasons: string[]) => {
  return reasons.includes("Copyright violation") || reasons.includes("Other Issues") || reasons.includes("Legal Issues");
};

export const PREMIUM_FEATURES = {
  CodingChallenge: "coding_challenge",
  CreateMentor: "create_mentor",
  VoiceChat: "voice_chat",
} as const;

export const MENTOR_VISIBILITY = {
  Public: "public",
  Private: "private",
} as const;

export const EXAMPLE_CHARACTERS = ["Aslan from Chronicles of Narnia", "Geralt of Rivia", "Elon Musk", "Jordan Peterson", "Gandalf", "Tony Stark"];

export const getRandomExampleCharacter = () => {
  return EXAMPLE_CHARACTERS[Math.floor(Math.random() * EXAMPLE_CHARACTERS.length)] ?? EXAMPLE_CHARACTERS[0];
};

export const WS_AUTH_ENDPOINT = "/broadcasting/auth";
