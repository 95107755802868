/* eslint-disable @typescript-eslint/naming-convention */
import { StorageSerializers, useSessionStorage } from "@vueuse/core";
import { addSeconds, fromUnixTime, getUnixTime, isPast } from "date-fns";
import { defu } from "defu";
import type { UseFetchOptions } from "nuxt/app";
import type { FetchError } from "ofetch";
import type { ApiErrorResponse } from "~/utils/types";
import { COOKIE_KEYS } from "~/constants";

export async function useFetchWithCache<TData, TBody = undefined>(url: string, options: UseFetchOptions<TData> = {}) {
  const error = shallowRef<FetchError<ApiErrorResponse<TBody>> | null>(null);

  const cached = useSessionStorage<TData>(options.key || url, null, {
    // By passing null as default it can't automatically
    // determine which serializer to use
    serializer: StorageSerializers.object,
  });

  const isExpired = !cached.value || isPast(addSeconds(fromUnixTime((cached.value as any).fetchedAt), Number(useRuntimeConfig().public.fetchTtl)));

  if (isExpired || url.includes("refresh=1")) {
    const { data, error: fetchError } = await useFetch(url, defu(options, setupDefaults<TData>(url)));

    if (fetchError.value) {
      error.value = fetchError.value;

      return { error, data };
    }

    cached.value = {
      ...data.value,
      fetchedAt: getUnixTime(new Date()),
    } as TData;
  }

  return { error, data: cached };
}

function setupDefaults<T>(url: string): UseFetchOptions<T> {
  const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

  return {
    baseURL: useRuntimeConfig().public.apiBaseUrl as string,

    key: url,

    headers: getApiRequestHeaders(token.value),
  };
}
