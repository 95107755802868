import { customFetch } from "~/utils/custom-fetch";
import type { FeatureListInfoType } from "~/utils/types";

export const isFeatureEnabledForUser = async (feature) => {
  return customFetch<{ status: boolean }>(`premium/features/check-access/${feature}`, {
    method: "POST",
  });
};

export const featureList = async (server = true) => {
  return useFetchWithCache<{ data: FeatureListInfoType }>("premium/features", { server });
};

export const featureListOnClient = async () => {
  return customFetch<{ data: FeatureListInfoType }>("premium/features");
};
