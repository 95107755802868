export const APP_THEME = {
  Dark: "dark",
  Light: "light",
  System: "system_default",
} as const;

export const MONACO_PROGRAMMING_LANG = [
  "plaintext",
  "clojure",
  "coffeescript",
  "c",
  "cpp",
  "csharp",
  "csp",
  "css",
  "cypher",
  "dart",
  "dockerfile",
  "elixir",
  "go",
  "graphql",
  "handlebars",
  "html",
  "ini",
  "java",
  "javascript",
  "julia",
  "kotlin",
  "less",
  "lua",
  "liquid",
  "markdown",
  "mysql",
  "pascal",
  "perl",
  "php",
  "powershell",
  "python",
  "redis",
  "ruby",
  "rust",
  "scala",
  "scss",
  "shell",
  "sql",
  "swift",
  "typescript",
  "xml",
  "yaml",
  "json",
];
