import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { WS_AUTH_ENDPOINT } from "~/constants";

declare global {
  interface Window {
    Pusher: Pusher;
    Echo: Echo;
  }
}

export default defineNuxtPlugin(() => {
  window.Pusher = Pusher;

  window.Echo = new Echo({
    broadcaster: "reverb",
    authEndpoint: WS_AUTH_ENDPOINT,
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    wssPort: import.meta.env.VITE_REVERB_PORT ?? 443,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? "https") === "https",
    enabledTransports: ["ws", "wss"],
    authorizer: (channel, _) => {
      return {
        authorize: async (socketId, callback) => {
          try {
            const { data, error } = await customFetch(WS_AUTH_ENDPOINT, {
              method: "POST",
              body: {
                socket_id: socketId,
                channel_name: channel.name,
              },
            });

            callback(error, data);
          } catch (error) {
            callback(error);
          }
        },
      };
    },
  });
});
