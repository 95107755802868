import { getRandomIntInclusive } from "~/utils/helpers";

export const IMAGE_URLS = {
  DefaultAvatar: "/png/avatar.png",
  LandingPageShapes: "/png/shapes.png",
  Rocket: "/png/rocket.png",
  Saturn: "/png/saturn.png",
  FullLogoWhite: "/png/full-logo-white.png",
  FullLogoGradient: "/png/full-logo-gradient.png",
  WordMarkWhite: "/png/wordmark-white.png",
  WordMarkPurple: "/png/wordmark-purple.png",
  CardPattern: "/png/card-patterns.png",
  DefaultCategoryThumbnail: "/png/menu.png",
  Trophy: "/png/trophy.png",
  FireIcon: "/png/fire.png",
  IceIcon: "/png/ice.png",
  ChatBg: "/webp/chat-bg.webp",
  SectionBanner: "/webp/section.webp",
} as const;

export const MASCOT_IMAGE_URLS = {
  MascotFrontView: "/png/mascots/mascot-1.png",
  MascotInShip: "/png/mascots/mascot-21.png",
  MascotPlanet: "/png/mascots/mascot-31.png",
  MascotStudying: "/png/mascots/mascot-41.png",
  MascotSwimming: "/png/mascots/mascot-51.png",
} as const;

export const purchaseUrl = (amount: number) => {
  if (amount <= 10_000) {
    return "/webp/purchase/10k.webp";
  }

  if (amount <= 20_000) {
    return "/webp/purchase/20k.webp";
  }

  if (amount <= 100_000) {
    return "/webp/purchase/100k.webp";
  }

  if (amount <= 200_000) {
    return "/webp/purchase/200k.webp";
  }

  if (amount <= 1_000_000) {
    return "/webp/purchase/1000k.webp";
  }

  return "/webp/purchase/5m.webp";
};

export const ANIMATION_URLS = {
  Award: "/gifs/award.gif",
  RightDecision: "/gifs/right-decision.gif",
  RightDecisionAlt: "/gifs/right-decision-2.gif",
  WrongDecision: "/gifs/wrong-decision.gif",
  WrongDecisionAlt: "/gifs/wrong-decision-2.gif",
  Quiz: "/gifs/quiz.gif",
  QuizAlt: "/gifs/quiz-2.gif",
  Question: "/gifs/question.gif",
  QuestionAlt: "/gifs/question-2.gif",
  ThumbsUp: "/gifs/thumbs-up.gif",
  ThumbsDown: "/gifs/thumbs-down.gif",
  Goal: "/gifs/goal.gif",
  Robot: "/gifs/robot.gif",
  RobotAlt: "/gifs/robot-2.gif",
  RobotAlt2: "/gifs/robot-3.gif",
  Chatbot: "/gifs/chatbot.gif",
  PageAnimation: "/gifs/page-animation-1.gif",
  ResetPassword: "/gifs/password.gif",
} as const;

export const ERROR_PAGE_IMAGES = {
  NotFound: "/webp/404.webp",
  Error: "/webp/error.webp",
} as const;

export const getQuizAnimation = () => {
  const icons = [ANIMATION_URLS.Quiz, ANIMATION_URLS.QuizAlt, ANIMATION_URLS.Question, ANIMATION_URLS.QuestionAlt];

  return icons[getRandomIntInclusive(0, icons.length - 1)];
};

export const getDecisionAnimationPair = () => {
  const pairs = [
    {
      right: ANIMATION_URLS.RightDecision,
      wrong: ANIMATION_URLS.WrongDecision,
    },
    {
      right: ANIMATION_URLS.RightDecisionAlt,
      wrong: ANIMATION_URLS.WrongDecisionAlt,
    },
    {
      right: ANIMATION_URLS.ThumbsUp,
      wrong: ANIMATION_URLS.ThumbsDown,
    },
  ];

  return pairs[getRandomIntInclusive(0, pairs.length - 1)];
};
