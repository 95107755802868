import { fromUnixTime } from "date-fns";
import type { FetchError } from "ofetch";
import type { Ref } from "vue";
import type { LocationQuery } from "vue-router";
import type { IAuthResponseModel } from "~/models/auth/auth-response.model";
import type { IAuthModel } from "~/models/auth/auth-user.model";
import type { IRegisterUserModel } from "~/models/auth/register-user.model";
import type { IUserModel } from "~/models/users/user.model";
import type { ApiErrorResponse } from "~/utils/types";
import { getApiRequestHeaders } from "~/utils/helpers";
import { customFetch } from "~/utils/custom-fetch";
import { COOKIE_KEYS } from "~/constants";

export type ResetPasswordPayload = { email: string; token: string; password: string; password_confirmation: string };

export const registerViaEmail = async (payload: IRegisterUserModel) => {
  const { data, error } = await customFetch<IAuthResponseModel, IRegisterUserModel>("auth/register", {
    method: "POST",
    body: payload,
    headers: getApiRequestHeaders(),
  });

  setAuthToken(data);

  return { data, error };
};

export const loginViaEmail = async (payload: IAuthModel) => {
  const { data, error } = await customFetch<IAuthResponseModel, IAuthModel>("auth/login", {
    method: "POST",
    body: payload,
    headers: getApiRequestHeaders(),
  });

  setAuthToken(data);

  return { data, error };
};

export const getSocialAuthUrl = async (provider: string) => {
  const { data, error } = await customFetch<{ data: string }>(`auth/social/auth/${provider}`, {
    method: "POST",
    headers: getApiRequestHeaders(),
  });

  return { data, error };
};

export const loginViaAccountProvider = async (provider: string, query: LocationQuery) => {
  const { data, error } = await customFetch<IAuthResponseModel>(`auth/social/login/${provider}`, {
    method: "POST",
    query,
    headers: getApiRequestHeaders(),
  });

  setAuthToken(data.value);

  return { data, error };
};

export const getUserFromToken = async (server = true) => {
  const { data, error } = await useCustomFetch<{ data: IUserModel }>("auth/user", {
    server,
  });

  return { data, error: error as Ref<FetchError<ApiErrorResponse<undefined>> | null> };
};

export const getUserFromTokenOnClient = async () => {
  return customFetch<{ data: IUserModel }>("auth/user");
};

export const fetchUserFromToken = async () => {
  return useFetchWithCache<{ data: IUserModel }>("auth/user");
};

export const fetchUserFromTokenClient = async () => {
  return customFetch<{ data: IUserModel }>("auth/user");
};

export const requestPasswordReset = async (email: string, callback_url: string) => {
  const { data, error } = await customFetch<
    {
      message: string;
    },
    {
      email: string;
      callback_url: string;
    }
  >("auth/password/send-reset-link", {
    method: "POST",
    body: { email, callback_url },
    headers: getApiRequestHeaders(),
  });

  return { data, error };
};

export const resetPassword = async (payload: ResetPasswordPayload) => {
  const { data, error } = await customFetch<{ message: string }, ResetPasswordPayload>("auth/password/reset", {
    method: "POST",
    body: payload,
    headers: getApiRequestHeaders(),
  });

  return { data, error };
};

export const logout = async () => {
  return customFetch<{ message: string }>("auth/logout", {
    method: "POST",
  });
};

const setAuthToken = (response: IAuthResponseModel | null) => {
  if (!response) {
    return;
  }

  const config = useRuntimeConfig();

  const userAuth = useCookie(COOKIE_KEYS.AuthToken, {
    expires: fromUnixTime(response.data.expires_at),
    secure: !config.public.isLocal,
  });
  userAuth.value = response.data.access_token;
};
