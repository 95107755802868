import Vue3Toastify, { toast, type ToastContainerOptions } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Vue3Toastify, {
    autoClose: 5000,
    position: "bottom-left",
    newestOnTop: true,
    theme: "dark",
    transition: toast.TRANSITIONS.SLIDE,
    multiple: true,
    limit: 3,
    bodyClassName: "text-sm",
  } as ToastContainerOptions);

  return {
    provide: { toast },
  };
});
