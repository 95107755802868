import type { NuxtError } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.config.errorHandler = (error) => {
    if (error instanceof Error) {
      const e = error as Partial<NuxtError>;

      if (!e.statusCode || e.statusCode >= 500) {
        useBugsnag().notify(error as Error);
      }
    } else {
      useBugsnag().notify(new Error(JSON.stringify(error)));
    }
  };
});
