import type { FetchError } from "ofetch";
import type { Ref } from "vue";
import type { IChangePasswordModel, IUpdateAccountModel } from "~/models/account/update-account.model";
import type { IUpdateSettingModel } from "~/models/account/update-setting.model";
import type { IMessageResponse } from "~/models/message-response.model";
import type { IUserModel } from "~/models/users/user.model";
import type { ApiErrorResponse } from "~/utils/types";
import { customFetch } from "~/utils/custom-fetch";

export const userProfile = async (username: string, refresh?: boolean) => {
  const url = refresh ? `account/profile/${username}?refresh=1` : `account/profile/${username}`;

  const { data, error } = await useFetchWithCache<{
    data: IUserModel;
    meta: {
      can_subscribe: boolean;
    };
  }>(url, {
    key: `account/profile/${username}`,
  });

  return { data, error: error as Ref<FetchError<ApiErrorResponse<undefined>> | null> };
};

export const userProfileClient = async (username: string) => {
  return customFetch<{
    data: IUserModel;
    meta: {
      can_subscribe: boolean;
    };
  }>(`account/profile/${username}`);
};

export const updateAccount = async (updateAccount: IUpdateAccountModel) => {
  return customFetch<IMessageResponse, IUpdateAccountModel>("account/me", {
    method: "PUT",
    body: updateAccount,
  });
};

export const updateSettings = async (updateSetting: IUpdateSettingModel) => {
  return customFetch<IMessageResponse, IUpdateSettingModel>("account/update-settings", {
    method: "POST",
    body: updateSetting,
  });
};

export const changePassword = async (changePassword: IChangePasswordModel) => {
  return customFetch<IMessageResponse, IChangePasswordModel>("account/change-password", {
    method: "POST",
    body: changePassword,
  });
};

export const spendPoints = async (body: { amount: number; reason: string }) => {
  return customFetch<IMessageResponse, { amount: number; reason: string }>("account/points/spend", {
    method: "POST",
    body,
  });
};

export const spendPointsOnServer = async (auth: string, body: { amount: number; reason: string }) => {
  const baseUrl = useRuntimeConfig().public.apiBaseUrl;

  try {
    await $fetch<{ data: string }>(`${baseUrl}/account/points/spend`, {
      method: "POST",
      headers: {
        Authorization: auth,
      },
      body,
    });
  } catch {
    // do nothing
  }
};
