import { useAuthStore } from "~/store/authStore";
import { PREMIUM_FEATURES, USER_ROLE } from "~/constants";
import type { FeatureListInfoType, PremiumFeatureType } from "~/utils/types";
import { featureList as fetchFeatureList, featureListOnClient } from "~/services/premium-features.service";

export const usePremiumFeatureStore = defineStore("premiumFeature", () => {
  const featureList = shallowRef<FeatureListInfoType>();

  const authStore = useAuthStore();

  const { user } = storeToRefs(authStore);

  const isPremiumAuthUser = computed(() => user.value?.role === USER_ROLE.Premium);

  const canCreateMentors = computed(() => {
    if (!user.value?.features) {
      return false;
    }

    return user.value.features[PREMIUM_FEATURES.CreateMentor] || false;
  });

  const canEnableVoiceChat = computed(() => {
    if (!user.value?.features) {
      return false;
    }

    return user.value.features[PREMIUM_FEATURES.VoiceChat] || false;
  });

  const authUserCanAccessFeature = (feature: PremiumFeatureType): boolean => {
    if (!user.value?.features) {
      return false;
    }

    return user.value.features[feature] || false;
  };

  const getFeatureList = async (server: boolean) => {
    if (server) {
      const { data, error } = await fetchFeatureList(server);

      if (data.value && !error.value) {
        featureList.value = data.value.data;
      }

      return;
    }

    const { data, error } = await featureListOnClient();

    if (data && !error) {
      featureList.value = data.data;
    }
  };

  return {
    featureList,
    canCreateMentors,
    isPremiumAuthUser,
    canEnableVoiceChat,
    authUserCanAccessFeature,
    getFeatureList,
  };
});
