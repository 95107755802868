import revive_payload_client_4sVQNw7RlN from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_7pzUtwM1Zj from "/vercel/path0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_yVLowv6hDl from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/vercel/path0/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cross_origin_prefetch_client_UzIc2qvBnC from "/vercel/path0/node_modules/nuxt/dist/app/plugins/cross-origin-prefetch.client.js";
import _64nuxt_scripts_D34jXE4xNh from "/vercel/path0/.nuxt/modules/@nuxt-scripts.mjs";
import plugin_vue3_A0OWXRrUgq from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_m9SjywKgWm from "/vercel/path0/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import defaults_SCHuOseEny from "/vercel/path0/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.mjs";
import siteConfig_XJTWbJLGEl from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/vercel/path0/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_prod_client_i1HKhqBLI4 from "/vercel/path0/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.mjs";
import plugin_BqLkNUef68 from "/vercel/path0/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import plugin_tbFNToZNim from "/vercel/path0/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_client_LcKgStRyi6 from "/vercel/path0/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import plugin_t2GMTTFnMT from "/vercel/path0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import plugin_w2VlvAEcpa from "/vercel/path0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import plugin_1UohGbtF8v from "/vercel/path0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
import error_handler_kEP5FliEXj from "/vercel/path0/plugins/error-handler.ts";
import laravel_echo_client_xHFhfajl0H from "/vercel/path0/plugins/laravel-echo.client.ts";
import vue3_toastify_OGYNDsiW9E from "/vercel/path0/plugins/vue3-toastify.ts";
import plugin_client_QHzhVBB2Ll from "/vercel/path0/.nuxt/nuxt-booster/plugin.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_7pzUtwM1Zj,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  cross_origin_prefetch_client_UzIc2qvBnC,
  _64nuxt_scripts_D34jXE4xNh,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_m9SjywKgWm,
  defaults_SCHuOseEny,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  plugin_prod_client_i1HKhqBLI4,
  plugin_BqLkNUef68,
  plugin_tbFNToZNim,
  plugin_client_LcKgStRyi6,
  plugin_t2GMTTFnMT,
  plugin_w2VlvAEcpa,
  plugin_1UohGbtF8v,
  analytics_client_mtqVw3YRXk,
  error_handler_kEP5FliEXj,
  laravel_echo_client_xHFhfajl0H,
  vue3_toastify_OGYNDsiW9E,
  plugin_client_QHzhVBB2Ll
]