import { getUnixTime } from "date-fns";
import type { LocationQuery } from "vue-router";
import { toast } from "vue3-toastify";
import type { IAuthResponseModel } from "~/models/auth/auth-response.model";
import type { IAuthModel } from "~/models/auth/auth-user.model";
import type { IRegisterUserModel } from "~/models/auth/register-user.model";
import type { IAuthUserModel, IUserModel } from "~/models/users/user.model";
import type { ResetPasswordPayload } from "~/services/auth.service";
import {
  fetchUserFromTokenClient,
  loginViaAccountProvider,
  loginViaEmail,
  registerViaEmail,
  requestPasswordReset,
  resetPassword,
} from "~/services/auth.service";
import type { ApiErrorResponse, LearningType, UserSettingsType } from "~/utils/types";
import { COOKIE_KEYS, LEARNING_TYPE, USER_SETTINGS } from "~/constants";

type RequestResetType = {
  email: string;
  callback_url: string;
};

type AuthErrorType = IRegisterUserModel | IAuthModel | RequestResetType | ResetPasswordPayload;

export const useAuthStore = defineStore(
  "auth",
  () => {
    const user = ref<IUserModel | undefined | null>(null);
    const authUser = ref<IAuthUserModel>();
    const authUserUpdatedAt = ref<number>();
    const requesting = ref<boolean>(false);
    const errors = shallowRef<ApiErrorResponse<AuthErrorType> | undefined | null>(null);
    const regData = ref<IRegisterUserModel>({
      first_name: "",
      last_name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
    });
    const authData = ref<IAuthModel>({
      username: "",
      password: "",
    });
    const authResponse = shallowRef<IAuthResponseModel | null>(null);

    const isAuthenticated = computed(() => Boolean(authUser.value) || Boolean(user.value));

    const hasAuthenticatedBefore = computed(() => {
      const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

      return Boolean(user.value) || Boolean(token.value);
    });

    const userLearningType = computed<LearningType>(() => {
      const type = getUserSetting(USER_SETTINGS.LearningType);

      if (type) {
        return type as LearningType;
      }

      return LEARNING_TYPE.Visual;
    });

    const { gtag } = useGtag();

    const fetchError = (property: string) => getValidationError<AuthErrorType>(property, errors.value);

    const registerUser = async () => {
      if (requesting.value) {
        return;
      }

      errors.value = null;
      requesting.value = true;
      authResponse.value = null;

      regData.value.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      regData.value.password_confirmation = regData.value.password;

      const { data, error } = await registerViaEmail(regData.value);
      requesting.value = false;
      errors.value = error?.data;

      if (errors.value || !data) {
        const message = errors.value?.message;

        if (message) {
          toast.error(message);
        }

        return;
      }

      gtag("event", "sign_up", {
        method: "email",
      });

      user.value = data.data.user;
      authResponse.value = data;

      toast.success("Welcome! Complete your profile for optimal experience.");
    };

    const loginUser = async () => {
      if (requesting.value) {
        return;
      }

      errors.value = null;
      requesting.value = true;
      authResponse.value = null;

      const { data, error } = await loginViaEmail(authData.value);
      requesting.value = false;
      errors.value = error?.data;

      if (errors.value) {
        const message = errors.value.message;

        if (message) {
          toast.error(message);
        }

        return;
      }

      if (data) {
        gtag("event", "login", {
          method: "email",
        });

        user.value = data.data.user;
        authResponse.value = data;
      }
    };

    const socialLogin = async (provider: string, query: LocationQuery) => {
      errors.value = null;
      requesting.value = true;
      authResponse.value = null;

      const { data, error } = await loginViaAccountProvider(provider, query);

      errors.value = error?.data;
      const message = error?.data?.message;

      if (errors.value && message) {
        toast.error(message);
      }

      if (data) {
        gtag("event", "login", {
          method: provider,
        });

        user.value = data.data.user;
        authResponse.value = data;
      }

      requesting.value = false;
    };

    const updateUser = (authUser: IUserModel | null) => (user.value = authUser);

    const getUserSetting = (settingName: UserSettingsType) => {
      if (!user.value) {
        return false;
      }

      const setting = user.value.settings?.find((s) => s.name === settingName);

      if (!setting) {
        return false;
      }

      return setting.setting;
    };

    const getAuthUser = async () => {
      const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

      if (requesting.value || !token.value) {
        return;
      }

      requesting.value = true;
      const { data, error } = await fetchUserFromTokenClient();
      requesting.value = false;

      if (!data || error) {
        if (error?.statusCode === 401) {
          user.value = null;
        }

        return;
      }

      user.value = data.data;
    };

    const sendResetLink = async (email: string, callbackUrl: string) => {
      if (requesting.value) {
        return {
          error: true,
          message: "",
        };
      }

      errors.value = null;
      requesting.value = true;

      const { data, error } = await requestPasswordReset(email, callbackUrl);

      requesting.value = false;
      errors.value = error?.data;

      if (errors.value) {
        const message = !error.statusCode || error.statusCode !== 422 ? error.data?.message || "There was an error sending the reset link" : "";

        return {
          error: true,
          message,
        };
      }

      return {
        error: false,
        message: data!.message,
      };
    };

    const passwordReset = async (payload: ResetPasswordPayload) => {
      if (requesting.value) {
        return {
          error: true,
          message: "",
        };
      }

      errors.value = null;
      requesting.value = true;

      const { data, error } = await resetPassword(payload);

      requesting.value = false;
      errors.value = error?.data;

      if (errors.value) {
        const message =
          !error.statusCode || error.statusCode !== 422 ? error.data?.message || "There was an error resetting the account password" : "";

        return {
          error: true,
          message,
        };
      }

      return {
        error: false,
        message: data?.message || "Success!",
      };
    };

    watch(user, (newUser) => {
      if (!newUser) {
        return;
      }

      authUser.value = {
        id: newUser.id,
        mentor_id: newUser.mentor_id,
        first_name: newUser.first_name,
        middle_name: newUser.middle_name,
        last_name: newUser.last_name,
        username: newUser.username,
        display_name: newUser.display_name,
        email: newUser.email,
        role: newUser.role,
        avatar: newUser.avatar,
        experience_points: newUser.experience_points,
        engagement_points: newUser.engagement_points,
        level: newUser.level,
      };

      authUserUpdatedAt.value = getUnixTime(new Date());
    });

    return {
      user,
      authUser,
      authUserUpdatedAt,
      errors,
      regData,
      authData,
      requesting,
      authResponse,
      isAuthenticated,
      hasAuthenticatedBefore,
      userLearningType,
      registerUser,
      loginUser,
      socialLogin,
      updateUser,
      getUserSetting,
      sendResetLink,
      passwordReset,
      fetchError,
      getAuthUser,
    };
  },
  {
    persist: {
      paths: ["authUser", "authUserUpdatedAt"],
    },
  },
);
