import { useAuthStore } from "~/store/authStore";
import { getUnixTime, subMinutes } from "date-fns";
import { COOKIE_KEYS } from "~/constants";

export default defineNuxtRouteMiddleware(async () => {
  const authStore = useAuthStore();
  const token = useCookie(COOKIE_KEYS.AuthToken, { readonly: true });

  if (
    (token.value && !authStore.user) ||
    !authStore.authUserUpdatedAt ||
    (authStore.authUserUpdatedAt && authStore.authUserUpdatedAt < getUnixTime(subMinutes(new Date(), 5)))
  ) {
    await authStore.getAuthUser();
  }
});
