import { addYears } from "date-fns";
import { COOKIE_KEYS } from "~/constants";

export function useCookieConsent() {
  const cookies = [
    {
      id: "necessary",
      name: "Strictly Necessary",
      description:
        // eslint-disable-next-line max-len
        "Strictly necessary cookies help make a website navigable by activating basic functions such as access to secure website areas. Without these cookies, the website would not be able to work properly.",
      cookies: [
        {
          id: "tokens",
          name: "Tokens",
          description: "We store your authentication tokens to keep you logged in",
        },
      ],
      enabled: false,
    },
    {
      id: "functional",
      name: "Functional",
      description: "Functional cookies allow the website to remember the user’s preferences, such as their theme settings.",
      cookies: [
        {
          id: "settings",
          name: "User Preferences",
          description: "We store your settings & preferences to offer you a better personalized experience",
        },
      ],
      enabled: false,
    },
    {
      id: "analytics",
      name: "Statistical",
      // eslint-disable-next-line max-len
      description:
        "Statistical cookies enable the website owner to analyze the website’s performance and user behavior by gathering and reporting data.",
      cookies: [
        {
          id: "google-analytics",
          name: "Google Analytics",
          description: "We track your page views, video watch times, and user interactions to offer you a better experience",
        },
      ],
      enabled: false,
    },
    {
      id: "marketing",
      name: "Marketing",
      description:
        // eslint-disable-next-line max-len
        "Marketing cookies track the user’s activity across different websites. They aim to show ads that match the user’s interests and preferences, which benefit both the publishers and the advertisers",
      cookies: [],
      enabled: false,
    },
  ];

  const { gtag, grantConsent } = useGtag();

  const cookieControls = ref({
    necessary: true,
    functional: true,
    analytics: true,
    marketing: true,
  });

  const enabledCookies = computed<Array<CookieConsentType>>(() => {
    const cookieConsent = useCookie(COOKIE_KEYS.CookieConsent, { readonly: true });

    if (!cookieConsent.value) {
      return [];
    }

    return typeof cookieConsent.value === "string"
      ? (JSON.parse(cookieConsent.value) as CookieConsentType[])
      : (cookieConsent.value as CookieConsentType[]);
  });

  const setupCookieSettings = () => {
    const config = useRuntimeConfig();

    cookieControls.value.necessary = true;

    for (const category in cookieControls.value) {
      if (enabledCookies.value.length > 0) {
        cookieControls.value[category] = Boolean(enabledCookies.value.find((cookie) => cookie.id === category)?.enabled);
      }

      if (cookieControls.value[category]) {
        const categoryCookies = cookies.find((cookie) => cookie.id === category);

        if (categoryCookies) {
          categoryCookies.enabled = true;
        }
      }
    }

    const hideConsentCookie = useCookie(COOKIE_KEYS.HideCookieConsent, {
      expires: addYears(new Date(), 1),
      secure: !config.public.isLocal,
    });

    if (!hideConsentCookie.value) {
      hideConsentCookie.value = "0";
    }
  };

  const saveSettings = () => {
    const config = useRuntimeConfig();

    grantConsent();

    gtag("consent", "update", {
      ad_storage: cookieControls.value.marketing ? "granted" : "denied",
      analytics_storage: cookieControls.value.analytics ? "granted" : "denied",
      personalization_storage: cookieControls.value.functional ? "granted" : "denied",
      functionality_storage: cookieControls.value.functional ? "granted" : "denied",
      security_storage: cookieControls.value.functional ? "granted" : "denied",
    });

    const settings: Array<CookieConsentType> = [];

    for (const category in cookieControls.value) {
      const categoryCookies = cookies.find((cookie) => cookie.id === category);

      if (categoryCookies) {
        settings.push({
          id: category as CookieConsentCategoryType,
          cookies: categoryCookies.cookies.map((cookie) => cookie.id),
          enabled: cookieControls.value[category] || category === "necessary",
        });
      }
    }

    const settingsCookie = useCookie(COOKIE_KEYS.CookieConsent, {
      expires: addYears(new Date(), 1),
      secure: !config.public.isLocal,
    });

    settingsCookie.value = JSON.stringify(settings);

    const hideConsentCookie = useCookie(COOKIE_KEYS.HideCookieConsent, {
      expires: addYears(new Date(), 1),
      secure: !config.public.isLocal,
    });

    hideConsentCookie.value = "1";

    window.location.reload();
  };

  return {
    cookies,
    cookieControls,
    enabledCookies,
    setupCookieSettings,
    saveSettings,
  };
}
